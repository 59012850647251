import React from 'react'
import { Link } from "gatsby"

export default function RelatedWorks({title, posts}) {
  return (
    <div className="project-list py-lg-5 py-4">
      <div className="py-120 pt-md-5 pt-4">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-12">
              <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: title}} />
            </div>
          </div>
          
        {posts && posts.map((caseStudy) => (
            <>
            <div className="row gx-xl-5 align-items-center project-card" key={caseStudy.slug}>
              <div className="col-4 col-md-3 mb-sm-0 mb-2 " data-aos="fade-up" data-aos-delay="100">
              {/* <img loading="lazy" src={caseStudy.caseStudyDetail && caseStudy.caseStudyDetail.smallFeaturedImage.mediaItemUrl} alt={caseStudy.caseStudyDetail && caseStudy.caseStudyDetail.smallFeaturedImage.altText} className="img-fluid w-100 gatsby-image-wrapper" /> */}
                <picture>
                  {/* <source media="(min-width:1800px)" srcSet={gal.galleryImage.node?.mediaDetails.sizes[5]?.sourceUrl} /> */}
                  {/* <source media="(min-width:1280px)" srcSet={gal.galleryImage.node?.mediaDetails.sizes[4]?.sourceUrl} /> */}
                  {/* <source media="(min-width:992px)" srcSet={gal.galleryImage.node?.mediaDetails.sizes[1]?.sourceUrl} /> */}
                  <source media="(min-width:768px)" srcSet={caseStudy.featuredImage?.node?.mediaItemUrl} />
                  <img loading="lazy" width="280" height="165" src={caseStudy.featuredImage.node?.mediaDetails.sizes[0]?.sourceUrl} alt={caseStudy.featuredImage?.node?.altText} className="img-fluid w-100 gatsby-image-wrapper" />
              </picture>
              </div>
              

              <div className="col-8 col-md-6 mb-sm-0 mb-2" data-aos="fade-up" data-aos-delay="200">
                <h3>{caseStudy.title}</h3>
                <div className="d-none d-sm-block">
                  <p className="mb-lg-1 mb-2" dangerouslySetInnerHTML={{__html: caseStudy.content}} />
                  <Link aria-label="Case Study" to={'/case-study/'+caseStudy.slug} className="btn text-uppercase btn-link d-inline-flex align-items-center">
                    READ THE STORY

                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                  </Link>
                </div>
              </div>

              <div className="col-12 d-sm-none" data-aos="fade-up" data-aos-delay="300">
                <p className="mb-lg-1 mb-2" dangerouslySetInnerHTML={{__html: caseStudy.content}} />
                <Link aria-label="Case Study SLug" to={'/case-study/'+caseStudy.slug} className="btn text-uppercase btn-link d-inline-flex align-items-center">
                  READ THE STORY

                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                </Link>
              </div>
            </div>
            

            <hr className="my-lg-5 my-sm-4 my-3" />
            </>
          ))}
        
        </div>
      </div>
    </div>
  )
}
