import * as React from "react"
import { graphql, Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ToolsSingle from "../components/caseToolSlider"
import RelatedWorks from "../components/relatedWorks"
import { useEffect } from "react"

// import ImageDumy from '../images/alkye-calc.png';

const CaseSudyDetailsPage = ({data}) => {
  const post = data.wpCaseStudy;
  const page = data.allWpPage.nodes[0];
  const [show, setShow] = React.useState(false);
  const [showGal, setShowGal] = React.useState(false);
  const [showGalVal, setShowGalValue] = React.useState('');

  const handle = () => setShow(!show);
  const handleModal = (e) => {setShowGal(!showGal); setShowGalValue(e)}

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handle();
      }
    };

    if (show) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (show) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [show]);

  return(
    <Layout HeadClass="header-inner">
      <Seo title={post.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={post.seo.metaDesc} focuskw={post.seo.focuskw} />
        <div className={`case-study px-md-4 text-white ${!post.caseStudyDetail.testimonialUserImage || !post.caseStudyDetail.websiteInsights ? 'case-study-height' : ''}`}>
          <div className="container">
            <div className="row mb-5 pb-md-5 align-items-end">
              <div className="col-md-9 col-lg-8 col-xl-8 col-xxl-9" data-aos="fade-up" data-aos-delay="200">
                <h1 className="title" dangerouslySetInnerHTML={{__html: post.title}} />
                <div dangerouslySetInnerHTML={{__html: post.content}} />
              </div>
              {/* customUrlForCalculator */}
              {/* <div className="col-md-3 col-lg-4 col-xl-4 col-xxl-3 mt-md-0 mt-4">
                {post.caseStudyDetail?.customUrlForCalculator && <Link target="_blank" to={post.caseStudyDetail?.customUrlForCalculator} className="btn btn-control btn-lg">Try Now</Link>}
              </div> */}
            </div>
            

            <div className="py-120">
              <div className="row justify-content-between">
                <div className="col-lg-3 mb-lg-0 mb-4" data-aos="fade-up" data-aos-delay="300">
                  <h4>{post.caseStudyDetail.whatWeDidTitle}</h4>

                  <ul className="list-unstyled technologies">
                    {post.caseStudyDetail.whatWeDidSpecification && post.caseStudyDetail.whatWeDidSpecification.map((spc, index) => (
                      <li key={index} dangerouslySetInnerHTML={{__html: spc.title}} />
                    ))}
                  </ul>
                </div>
                <div className="col-lg-8" data-aos="fade-up" data-aos-delay="400"  dangerouslySetInnerHTML={{__html: post.caseStudyDetail.whatWeDidDescription}} />
              </div>
              
            </div>
            
          </div>
          
          {!post.caseStudyDetail.calculators && post.caseStudyDetail.testimonialUserImage && (
            <div className="py-120 pt-4 pt-sm-5">
              <div className="container container-sm">
                <div className="row align-items-center gx-lg-5 client-card" data-aos="fade-up" data-aos-delay="200">
                  <div className="col-sm-7 mb-sm-0 mb-4">
                    <h2>{post.caseStudyDetail.testimonialContent}</h2>

                    <div className="client-name mt-md-5 mt-4 position-relative d-flex align-items-center">
                      <h5><strong>{post.caseStudyDetail.testimonialUserName}</strong>{post.caseStudyDetail.testimonialUserDesignation}</h5>
                      <button onClick={handle} className="button-circle btn p-0 border-0 video-modal" data-bs-toggle="modal" data-bs-target="#VideoModal" type="button">
                        &nbsp;
                        {/* <StaticImage
                          src="/static/watch-testimonial.svg"
                          quality={100}
                          formats={["auto", "webp", "avif", "svg"]}
                          className="img-fluid" alt="project"
                        /> */}
                        <img src="/watch-testimonial.svg" alt="project" className="img-fluid" />
                      </button>
                    </div>
                  </div>
                  
                  <div className="col-sm-5">
                    <div className="client-pic">
                      <img src={post.caseStudyDetail.testimonialUserImage && post.caseStudyDetail.testimonialUserImage.mediaItemUrl} alt={post.caseStudyDetail.testimonialUserImage && post.caseStudyDetail.testimonialUserImage.altText} className="img-fluid gatsby-image-wrapper" />
                    </div>
                  </div>
                  
                </div>
                
              </div>
              
            </div>
          )}

            {!post.caseStudyDetail.calculators && (
              <div className="container mt-md-5 mt-4">
                <div className="website-info">
                  {post.caseStudyDetail.websiteInsights && post.caseStudyDetail.websiteInsights.map((ins, index) => (
                    <div className="d-flex mb-lg-5 mb-4 pt-xl-4 pt-2 align-items-start" data-aos="fade-up" data-aos-delay="200" key={index}>
                      <img src={ins.icon && ins.icon.mediaItemUrl} alt={ins.icon && ins.icon.altText} className="img-fluid gatsby-image-wrapper" />

                      <div className="ps-xl-5 ps-md-4 ps-2">
                        <p><strong className="d-block" dangerouslySetInnerHTML={{__html: ins.value}} /> {ins.title}</p>            
                      </div>
                    </div>
                  ))}
                  
                </div>
                
              </div>
            )}


          {post.caseStudyDetail.calculators?.slice(0, 1).map((caseStudy, index) => (
            <div className="container">
              <div className={`row gx-xl-5 justify-content-center ${caseStudy.position !== "Center" ? 'align-items-center' : ''} project-card`} key={caseStudy.slug}>
                <div className={caseStudy.position === "Center" ? 'col-md-12 mb-md-4 mb-3 pb-xxl-3 pb-lg-2' : caseStudy.position === "Left" ? 'col-sm-7 col-xxl-6 col-12 mb-md-0 mb-3' : 'col-sm-7 col-xxl-6 col-12 mb-md-0 mb-3 order-sm-2'} data-aos='fade-up' data-aos-delay="200">
                  {/* {caseStudy.position === "Center" ? (
                    <Link to={caseStudy.customUrlForCalculator}><img src={caseStudy.featuredImage && caseStudy.featuredImage.node.mediaItemUrl} alt={caseStudy.featuredImage && caseStudy.featuredImage.node.altText} className="img-fluid w-100 gatsby-image-wrapper" /></Link>
                  ) : (
                    <Link to={caseStudy.customUrlForCalculator}><img src={caseStudy.smallFeaturedImage && caseStudy.smallFeaturedImage.mediaItemUrl} alt={caseStudy.smallFeaturedImage && caseStudy.smallFeaturedImage.altText} className="img-fluid w-100 gatsby-image-wrapper" /></Link>
                  )} */}
                  <Link target={caseStudy.customUrlForCalculator ? "_blank" : '_self'} to={caseStudy.customUrlForCalculator ? caseStudy.customUrlForCalculator : '/case-study/'+caseStudy.customUrlForTender}>
                    {/* <img src={ImageDumy} alt="img" className="img-fluid w-100 gatsby-image-wrapper" /> */}
                    <img src={caseStudy.featuredImage && caseStudy.featuredImage.mediaItemUrl} alt={caseStudy.featuredImage && caseStudy.featuredImage.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                  </Link>
                </div>

                <div className={caseStudy.position === "Center" ? 'col-10 col-sm-9 col-md-8 col-lg-6 col-xxl-8' : 'col-10 col-xxl-6 col-sm-5'} data-aos='fade-up' data-aos-delay="400">
                  <Link target={caseStudy.customUrlForCalculator ? "_blank" : '_self'} to={caseStudy.customUrlForCalculator ? caseStudy.customUrlForCalculator : '/case-study/'+caseStudy.customUrlForTender}>
                    <h3>{caseStudy.title}</h3>
                    <div dangerouslySetInnerHTML={{__html: caseStudy.content}} />
                    <p className="mb-lg-1 mb-2"></p>
                    <span className="btn text-uppercase btn-link text-white d-inline-flex align-items-center">
                      {caseStudy.buttonText ? caseStudy.buttonText : 'READ THE STORY'}

                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          
        </div>

      {post.caseStudyDetail.calculators && (
        <div className="project-list py-120">
          <div className="container">
            {post.caseStudyDetail.calculators?.slice(1).map((caseStudy, index) => (
              <div className={`row gx-xl-5 mb-135 justify-content-center ${caseStudy.position !== "Center" ? 'align-items-center' : ''} project-card`} key={caseStudy.slug}>
                <div className={caseStudy.position === "Center" ? 'col-md-12 mb-md-4 mb-3 pb-xxl-3 pb-lg-2' : caseStudy.position === "Left" ? 'col-sm-7 col-xxl-6 col-12 mb-md-0 mb-3' : 'col-sm-7 col-xxl-6 col-12 mb-md-0 mb-3 order-sm-2'} data-aos='fade-up' data-aos-delay="200">
                  {/* {caseStudy.position === "Center" ? (
                    <Link to={'/case-study/'+caseStudy.slug}><img src={caseStudy.featuredImage && caseStudy.featuredImage.node.mediaItemUrl} alt={caseStudy.featuredImage && caseStudy.featuredImage.node.altText} className="img-fluid w-100 gatsby-image-wrapper" /></Link>
                  ) : (
                    <Link to={'/case-study/'+caseStudy.slug}><img src={caseStudy.smallFeaturedImage && caseStudy.smallFeaturedImage.mediaItemUrl} alt={caseStudy.smallFeaturedImage && caseStudy.smallFeaturedImage.altText} className="img-fluid w-100 gatsby-image-wrapper" /></Link>
                  )} */}
                  <Link target={caseStudy.customUrlForCalculator ? "_blank" : '_self'} to={caseStudy.customUrlForCalculator ? caseStudy.customUrlForCalculator : '/case-study/'+caseStudy.customUrlForTender}>
                    {/* <img src={ImageDumy} alt="img" className="img-fluid w-100 gatsby-image-wrapper" /> */}
                    <img src={caseStudy.featuredImage && caseStudy.featuredImage.mediaItemUrl} alt={caseStudy.featuredImage && caseStudy.featuredImage.altText} className="img-fluid w-100 gatsby-image-wrapper" />
                  </Link>
                </div>

                <div className={caseStudy.position === "Center" ? 'col-10 col-sm-9 col-md-8 col-lg-6 col-xxl-8' : 'col-10 col-xxl-6 col-sm-5'} data-aos='fade-up' data-aos-delay="400">
                  <Link target={caseStudy.customUrlForCalculator ? "_blank" : '_self'} to={caseStudy.customUrlForCalculator ? caseStudy.customUrlForCalculator : '/case-study/'+caseStudy.customUrlForTender}>
                    <h3>{caseStudy.title}</h3>
                    <div dangerouslySetInnerHTML={{__html: caseStudy.content}} />
                    <p className="mb-lg-1 mb-2"></p>
                    <span className="btn text-uppercase btn-link d-inline-flex align-items-center">
                    {caseStudy.buttonText ? caseStudy.buttonText : 'READ THE STORY'}

                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      

      
        <div className={`modal modal-video fade ${show ? 'show' : ''}`} id="VideoModal" tabindex="-1" aria-labelledby="VideoModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content border-0 rounded-0">
              <div className="modal-body p-0 position-relative">
                <button type="button" onClick={handle} className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>

                <iframe src={show ? `https://www.youtube.com/embed/${post.caseStudyDetail.testimonialYoutubeLink}?autoplay=1&rel=0&showinfo=0` : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        
        <div className={`modal modal-video fade ${showGal ? 'show' : ''}`} id="VideoModal" tabindex="-1" aria-labelledby="VideoModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content border-0 rounded-0">
              <div className="modal-body p-0 position-relative">
                <button type="button" onClick={handleModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close">Close</button>

                <iframe src={showGal ? `https://www.youtube.com/embed/${removeHTML(showGalVal)}?autoplay=1&rel=0&showinfo=0` : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>

        {!post.caseStudyDetail.calculators && (
          <div className="project-flow py-120 text-center">
            {post.caseStudyDetail.gallery && post.caseStudyDetail.gallery.map((gal, index) => (
              gal.galleryImage.caption ? (
                <img onMouseUp={() => handleModal(gal.galleryImage.caption)} style={{cursor: 'pointer'}} src={gal.galleryImage && gal.galleryImage.mediaItemUrl} alt={gal.galleryImage && gal.galleryImage.altText} className="img-fluid gatsby-image-wrapper" key={index} data-aos="fade-up" data-aos-delay="200" />
                ):(
                <img src={gal.galleryImage && gal.galleryImage.mediaItemUrl} alt={gal.galleryImage && gal.galleryImage.altText} className="img-fluid gatsby-image-wrapper" key={index} data-aos="fade-up" data-aos-delay="200" />
              )
            ))}
          </div>
        )}

        {post.caseStudyDetail.tools && <ToolsSingle Background="bg-grad" posts={post.caseStudyDetail.tools && post.caseStudyDetail.tools} />}
        
        <RelatedWorks title={page.caseStudy.relatedWorksTitle} posts={post.caseStudyDetail.otherRelatedWorks && post.caseStudyDetail.otherRelatedWorks} />    
    
    </Layout>
  )
}

export default CaseSudyDetailsPage

function removeHTML(str){ 
  if (typeof document !== undefined) {
    var tmp = document.createElement("p");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }
}

export const query = graphql `
  query($slug: String) {
    allWpPage(filter: {slug: {eq: "case-study"}}) {
      nodes {
        caseStudy {
          relatedWorksTitle
        }
      }
    }
    wpCaseStudy(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
      }
      title
      content
      caseStudyDetail {
        whatWeDidTitle
        whatWeDidDescription
        whatWeDidSpecification {
          title
        }
        testimonialContent
        testimonialUserDesignation
        testimonialUserName
        testimonialUserImage {
          altText
          mediaItemUrl
        }
        testimonialYoutubeLink
        websiteInsights {
          title
          value
          icon {
            altText
            mediaItemUrl
          }
        }
        gallery {
          galleryImage {
            altText
            caption
            mediaItemUrl
          }
        }
        tools {
          ... on WpTool {
            slug
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
          }
        }
        otherRelatedWorks {
          ... on WpCaseStudy {
            title
            slug
            content
            featuredImage {
              node {
                altText
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
            caseStudyDetail {
              smallFeaturedImage {
                altText
                mediaItemUrl
              }
            }
          }
        }
        calculators {
          content
          buttonText
          customUrlForCalculator
          customUrlForTender
          title
          position
          featuredImage {
            altText
            mediaItemUrl
          }
        }
      }
    }
  }
`